import { useTheme } from "../ThemeProvider";
import "./footer.css";

function Footer() {
  const theme = useTheme();
  return (
    <div
      className={`${
        theme === "light" ? "footerDiv" : "footerDark"
      }  flex-column`}
      id="footer"
      style={{ paddingTop: "100px" }}
    >
      <div className=" row flex" style={{ paddingBottom: "115px" }}>
        <div className="col-md-4 footerLabel1">
          <p className="text-start plabel mb-3">Our location</p>
          <p className="text-start mb-1">
          Modern Profound Tech Park,
          </p>
          <p className="text-start mb-1">3rd Floor, Unispace, Kondapur</p>
          <p className="text-start mb-1">Hyderabad, Telangana, 500081</p>
          <p className="text-start mt-3 mb-1">sales@vendventure.in</p>
          <p className="text-start  fs-6"> Phone: +91 99854 02433</p>
        </div>
        <div className="col-md-4 footerlabel2">
          <p className="text-start plabel mb-3">Quick links</p>
          <p className="text-start mb-1">About Us</p>
          <p className="text-start mb-1">Website Development</p>
          <p className="text-start mb-1">Mobile App Development</p>
          <p className="text-start mb-1">Digital Marketing</p>
          <p className="text-start mb-1">UIUX And visual, Graphic Designing</p>
        </div>
        <div className="col-md-4 footerlist">
          <p className="text-start plabel mb-3">Follow Us</p>
          <div className="d-flex gap-3">
            <a href="#" className="fa fa-facebook"></a>
            <a href="#" className="fa fa-twitter"></a>
            <a href="#" className="fa fa-youtube"></a>
            <a href="#" className="fa fa-instagram"></a>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "15px" }}>
        <div className="col hrLine">
          <span></span>
        </div>
        <p className="text-center footerights">
          @ 2025 VendVenture Pvt Ltd - Job Portal. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
