import streetjob from '../../assests/images/streetjob.png';
import streetjobmob from '../../assests/images/streetjobmob.png';
import mobilestreetjobmob from '../../assests/images/mobilestreetjobmob.png';
import {useTheme} from '../ThemeProvider';
import './aboutjob.css'
function Aboutjob() {
  const theme = useTheme();
  return (
  <div className={`${theme === 'light' ? 'bgDivLight' : 'bgDiv'} row containerDiv`}  >
    <div className={`${theme === 'light' ? 'col-md-6 text-start' : 'col-md-6 text-start'} alignTop`}>
      <h1 className={`${theme === 'light' ? 'm-0 text-dark headerLabelsmain' : 'm-0 text-light headerLabelsmain'}`}>We know</h1>
      <h1 className={`${theme === 'light' ? 'text-dark headerLabelsmain' : 'text-light headerLabelsmain'}`}>value about the JOB</h1>
      <p className={`${theme === 'light' ? 'text-dark paragraph' : 'text-light paragraph'} py-2`}>Your one stop platform for anything, and 
     everything on job</p>
     <div className='d-flex pt-4'>
        <button type="button" className={`${theme === 'light' ? 'buttonprimary me-3' : 'dark me-3'}`} >Get</button>
        <button type="" className={`${theme === 'light' ? 'buttonprimary me-3' : 'dark me-3'}`}>Watch Video</button>
     </div>
    </div>
    <div className="col-md-6 d-none d-sm-block">
     <img src={streetjob} alt="streetjob" width={500}/>
    </div>
    <div className="col-md-6 d-block d-sm-none mt-4">
     <img src={streetjobmob} alt="streetjob" width={280}/>
    </div>

    {/* <div className="col text-start mt-6" id="product" style={{marginTop:'8%'}}>
    <h2 className={`${theme === 'light' ? 'm-0 text-dark headerLabels' : 'm-0 text-light headerLabels'}`}>Product</h2>
      <p className={`${theme === 'light' ? 'mt-4 text-dark text paragraph' : 'mt-4 text-light text paragraph'}`}>Welcome to Street Jobs App, where opportunities meet talent! Whether you're looking for your dream job or searching for the perfect candidate to join your team, we're here to make the process smooth and efficient.</p>
    </div>
    */}
  </div>
  );
}

export default Aboutjob;
