import mobilestreetjob from "../../assests/images/mobilestreetjob.png";
import mobilestreetjobmob from "../../assests/images/mobilestreetjobmob.png";
import googlepaly from "../../assests/images/googlepaly.png";
import googlepalymob from "../../assests/images/googlepalymob.png";
import appstoremob from "../../assests/images/appstoremob.png";
import appstore from "../../assests/images/appstore.png";
import Icon from "../../assests/images/Icon.png";
import Icon2 from "../../assests/images/Icon2.png";
import Icon3 from "../../assests/images/Icon3.png";
import Icon4 from "../../assests/images/Icon4.png";
import Icondark from "../../assests/images/Icondark.png";
import Icondark1 from "../../assests/images/Icondark1.png";
import Icondark3 from "../../assests/images/Icondark3.png";
import Icondark4 from "../../assests/images/Icondark4.png";
import { useTheme } from "../ThemeProvider";
import "./streetjobapp.css";

function Streetjobapp() {
  const theme = useTheme();
  return (
    <div class="row containerDiv" id="product" style={{ paddingTop: "90px" }}>
      <div className="col-12 text-start mt-6">
        <h2
          className={`${
            theme === "light"
              ? "m-0 text-dark headerLabels"
              : "m-0 text-light headerLabels"
          }`}
        >
          Product
        </h2>
        <p
          className={`${
            theme === "light"
              ? "mt-4 text-dark text paragraph"
              : "mt-4 text-light text paragraph"
          }`}
        >
          Welcome to Street Jobs App, Looking for a job that keeps you active
          and engaged? Check out these exciting street job opportunities in your
          area! Are you ready to embark on a journey where every corner holds a
          new opportunity? Explore these diverse street job options that let you
          thrive in the hustle and bustle of urban life. Where opportunities
          meet talent! Whether you're looking for your dream job or searching
          for the perfect candidate to join your team, we're here to make the
          process smooth and efficient.
        </p>
      </div>

      <div
        class="col-lg-4 text-start d-none d-sm-block"
        style={{ paddingTop: "20px" }}
      >
        <img src={mobilestreetjob} alt="mobilestreetjob" width={360} />
      </div>
      <div class="col-lg-4 text-start d-block d-sm-none">
        <img src={mobilestreetjobmob} alt="mobilestreetjob" width={280} />
      </div>
      <div
        class={`${
          theme === "light" ? "m-0 bgStreeDivLight" : "bgStreeDiv"
        } col-lg-8 mt-4 alignLeft`}
      >
        <h2
          className={`${
            theme === "light" ? "m-0 text-dark" : "m-0 text-light"
          } text-start headerLabels`}
        >
          Street Jobs App{" "}
        </h2>
        <p
          className={`${
            theme === "light" ? "m-0 text-dark" : "m-0 text-light"
          } text-start paragraph paraLineheight my-2 mb-3`}
        >
          Your search ends here
        </p>
        <p
          className={`${
            theme === "light" ? "m-0 text-dark" : "m-0 text-light"
          } text-start paragraph paraLineheight fs-5 fw-bold`}
        >
          Why Choose Us:
        </p>
        <ul>
          <li
            className={`${
              theme === "light" ? "m-0 text-dark" : "m-0 text-light"
            } text-start paragraph mt-3`}
          >
            <span className="fw-bold">Extensive Network:</span> Connect with so
            many job providers and talented professionals across industries.
          </li>
          <li
            className={`${
              theme === "light" ? "text-dark" : "text-light"
            } text-start paragraph mt-3`}
          >
            <span className="fw-bold">User-Friendly Interface:</span> Easily
            navigate through job listings and applications.
          </li>
          <li
            className={`${
              theme === "light" ? "text-dark" : "text-light"
            } text-start paragraph mt-3`}
          >
            <span className="fw-bold">Expert Advice:</span> Access to career
            resources and guidance from industry experts.
          </li>
          <li
            className={`${
              theme === "light" ? "text-dark" : "text-light"
            } text-start paragraph mt-3`}
          >
            <span className="fw-bold">Secure Platform:</span> Ensuring the
            privacy and security of your personal information.
          </li>
        </ul>
        <p
          className={`${
            theme === "light" ? "text-dark" : "text-light"
          } text-start text paragraph mt-4`}
        >
          Get it now:
        </p>
        <div className="d-flex gap-3 mt-2  d-none d-sm-block float-start">
          <a
            href="https://play.google.com/store/apps/details?id=in.vendventure.streetjobs"
            target="_blank"
          >
            <img
              src={googlepaly}
              alt="googleplay"
              width={150}
              height={50}
              className="me-3"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/street-jobs/id6502700652"
            target="_blank"
          >
            <img src={appstore} alt="googleplay" width={150} height={50} />
          </a>
        </div>
        <div className="d-flex gap-3 mt-2 d-block d-sm-none">
          <a
            href="https://play.google.com/store/apps/details?id=in.vendventure.streetjobs"
            target="_blank"
          >
            <img src={googlepalymob} alt="googleplay" width={120} height={36} />
          </a>
          <a
            href="https://apps.apple.com/us/app/street-jobs/id6502700652"
            target="_blank"
          >

          <img src={appstoremob} alt="googleplay" width={120} height={36} /></a>
        </div>
      </div>
      <div class="col text-start mt-4">
        <h2
          className={`${
            theme === "light" ? "text-dark" : "text-light"
          } my-4 py-2 headerLabels`}
        >
          Street Job App Status
        </h2>
        <div className="row mt-4 paragraph">
          <div className="col-lg-3 col-md-6">
            <div
              class={`${
                theme === "light" ? "bg-light text-dark" : "bg-dark text-light"
              } card mb-3 cardRadius `}
              style={{ height: "85px", borderRadius: "7px" }}
            >
              <div class="row g-0">
                <div class="col-2">
                  <img
                    src={`${theme === "light" ? Icon : Icondark}`}
                    class="img-fluid rounded-start"
                    alt="icon"
                    width={40}
                    style={{ margin: "22px 13px" }}
                  />
                </div>
                <div class="col-10 mt-1">
                  <div class="card-body statuscard ms-1">
                    <h5 class="card-title">5,000 +</h5>
                    <p>Live jobs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              class={`${
                theme === "light" ? "bg-light text-dark" : "bg-dark text-light"
              } card mb-3 cardRadius `}
              style={{ height: "85px", borderRadius: "7px" }}
            >
              <div class="row g-0">
                <div class="col-2">
                  <img
                    src={`${theme === "light" ? Icon3 : Icondark3}`}
                    class="img-fluid rounded-start"
                    alt="icon"
                    width={40}
                    style={{ margin: "22px 13px" }}
                  />
                </div>
                <div class="col-10 mt-1">
                  <div class="card-body statuscard ms-1">
                    <h5 class="card-title">100 +</h5>
                    <p>Companies</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              class={`${
                theme === "light" ? "bg-light text-dark" : "bg-dark text-light"
              } card mb-3 cardRadius `}
              style={{ height: "85px", borderRadius: "7px" }}
            >
              <div class="row g-0">
                <div class="col-2">
                  <img
                    src={`${theme === "light" ? Icon2 : Icondark1}`}
                    class="img-fluid rounded-start"
                    alt="icon"
                    width={40}
                    style={{ margin: "22px 13px" }}
                  />
                </div>
                <div class="col-10 mt-1">
                  <div class="card-body statuscard ms-1">
                    <h5 class="card-title">1,000 +</h5>
                    <p> Candidates</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              class={`${
                theme === "light" ? "bg-light text-dark" : "bg-dark text-light"
              } card mb-3 cardRadius `}
              style={{ height: "85px", borderRadius: "7px" }}
            >
              <div class="row g-0">
                <div class="col-2">
                  <img
                    src={`${theme === "light" ? Icon4 : Icondark}`}
                    class="img-fluid rounded-start"
                    alt="icon"
                    width={40}
                    style={{ margin: "22px 13px" }}
                  />
                </div>
                <div class="col-10 mt-1">
                  <div class="card-body statuscard ms-1">
                    <h5 class="card-title">5,000 +</h5>
                    <p>New Jobs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Streetjobapp;
