import vendventurelogo from "../../assests/images/vendventurelogo1.png";
import vendventurelogodark from "../../assests/images/vend-venture-logodark.png";
import Aboutjob from "../aboutjob/aboutjob";
import Streetjobapp from "../streetjobapp/streetjobapp";
import Aboutus from "../aboutus/aboutus";
import Team from "../team/team";
import Client from "../client/client";
import Footer from "../footer/footer";
import { useTheme } from "../ThemeProvider";
import "./navbar.css";
import { useEffect, useState } from "react";

function NavbarHeader({ toggleTheme }) {
  const [activeSection, setActiveSection] = useState("home");
  const theme = useTheme();

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["home", "product", "aboutus", "team", "footer"];
      const scrollPosition = window.scrollY;

      // Find the currently active section
      let currentSection = "home";
      sections.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement.offsetTop <= scrollPosition + 150) {
          currentSection = section;
        }
      });

      setActiveSection(currentSection);

      // Add active class to the corresponding nav link
      handleNavLinkClick(currentSection);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = (mode) => {
    toggleTheme(mode);
  };

  function handleNavLinkClick(sectionId) {
    // Remove active class from all nav links
    document.querySelectorAll(".navbar-nav .nav-link").forEach((link) => {
      link.classList.remove("active");
    });

    // Add active class to the clicked nav link
    document
      .querySelector(`.navbar-nav .nav-link[href="#${sectionId}"]`)
      .classList.add("active");
  }

  return (
    <div>
      <nav
        className={`${
          theme === "light"
            ? "navbar sticky-top navbar-expand-lg navbar-light bg-light pt-4"
            : "navbar sticky-top  navbar-dark navbar-expand-lg navDarkbgColor  pt-4"
        } font`}
        
      >
        <div className="container-fluid ">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="row container-fluid">
              <div className="col-lg-2">
                <a href="#home" onClick={() => handleNavLinkClick("home")}>
                  <img
                    src={
                      theme === "light" ? vendventurelogo : vendventurelogodark
                    }
                    alt="logo"
                  />
                </a>
              </div>

              <div className="col-lg-7">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex justify-content-end">
                  <li
                    className={`nav-item pe-2 ${
                      activeSection === "home" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#home"
                      onClick={() => handleNavLinkClick("home")}
                    >
                      Home
                    </a>
                  </li>

                  <li
                    className={`nav-item pe-2 ${
                      activeSection === "product" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      href="#product"
                      onClick={() => handleNavLinkClick("product")}
                    >
                      Products
                    </a>
                  </li>
                  <li
                    className={`nav-item pe-2 ${
                      activeSection === "aboutus" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      href="#aboutus"
                      onClick={() => handleNavLinkClick("aboutus")}
                    >
                      About us
                    </a>
                  </li>
                  <li
                    className={`nav-item pe-2 ${
                      activeSection === "team" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      href="#team"
                      onClick={() => handleNavLinkClick("team")}
                    >
                      Team
                    </a>
                  </li>

                  <li
                    className={`nav-item pe-2 ${
                      activeSection === "footer" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      href="#footer"
                      onClick={() => handleNavLinkClick("footer")}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3">
                <form className="d-flex justify-content-center">
                  <div className="row " align="center">
                    <div
                      className="col-lg-6 mt-2 d-none d-sm-block"
                      align="end"
                    >
                      <div className="input-group flex-nowrap searchInput me-4 w-100">
                        <span
                          className="input-group-text searchIcon pe-2"
                          id="addon-wrapping"
                        >
                          <i
                            class="fa fa-search"
                            style={{ color: "#D3D3D3", fontSize: "14px" }}
                          ></i>
                        </span>
                        <input
                          type="text"
                          className=" searchInputField ps-0 pt-1 pb-1"
                          style={{
                            width: "100%",
                            color: "#fff",
                            fontSize: "14px",
                          }}
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-2 d-sm-none pe-5" align="end">
                      <div className="input-group flex-nowrap searchInput  w-100">
                        <span
                          className="input-group-text searchIcon pe-2"
                          id="addon-wrapping"
                        >
                          <i
                            class="fa fa-search"
                            style={{ color: "#D3D3D3", fontSize: "14px" }}
                          ></i>
                        </span>
                        <input
                          type="text"
                          className=" searchInputField ps-0 pt-1 pb-1"
                          style={{
                            width: "100%",
                            color: "#fff",
                            fontSize: "14px",
                          }}
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center mt-2 ">
                      <div className="row d-flex flex-row ">
                        <div className="col-4">
                          <p
                            className={`${
                              theme === "light" ? "text-dark" : "text-light"
                            } `}
                          >
                            Theme
                          </p>
                        </div>
                        <div className="col-8 d-flex mt-1 ">
                          <button
                            type="button"
                            className="darkbutton  mx-2 "
                            onClick={() => handleToggle("dark")}
                          />
                          <button
                            type="button"
                            className="lightbutton "
                            onClick={() => handleToggle("light")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* <input className="form-control me-2 searchLabel" type="search" placeholder="Search" aria-label="Search"/> */}

              {/* <div className='col-sm-12 border border-dark d-flex'>
        <div className='col-sm-12 col-lg-7 border border-primary'>
          <div className="input-group flex-nowrap searchInput me-3 w-100">
            <span className="input-group-text searchIcon pe-2" id="addon-wrapping"><i class="fa fa-search" style={{color:'#fff',fontSize:'19px'}}></i></span>
             <input type="text" className=" searchInputField ps-0" style={{width: '100%'}} placeholder="Search"/>
          </div>

        </div>
        <div className='col-sm-12 col-lg-5 border border-secondary'>
          <div className='d-flex'>
          
            <span style={{lineHeight:'38px'}} className={`${theme === 'light' ? 'text-dark' :'text-light'}`}>Theme</span>
             <button type="button" className='darkbutton m-2' onClick={()=>handleToggle('dark')}/>
            <button type="button" className='lightbutton mt-2' onClick={()=>handleToggle('light')}/>

          </div>

        </div>

      </div> */}
            </div>
          </div>
        </div>
      </nav>

      <div
        id="home"style={{ paddingTop: "40px", position: "relative", }}
        
      >
        <Aboutjob />
        <div>
          <div
            className={`${
              theme === "light"
                ? "streeappdiv streeappdiv1"
                : "streetappdarkDiv"
            }`}
          >
            <div>
              <Streetjobapp />
            </div>
            <Aboutus />
          </div>
          <div className={`${theme === "light" ? "teambg" : "teambgDark"}`}>
            <Team />
            <Client />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default NavbarHeader;
