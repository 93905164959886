import team1 from '../../assests/images/team1.png';
import team2 from '../../assests/images/team2.png';
import arrowright from '../../assests/images/fi_arrow-right.png';
import {useTheme} from '../ThemeProvider';
import './team.css';

function Team() {
  const theme = useTheme();
  return (
  <div className={`row containerDiv`} id="team" style={{ paddingTop: '50px' }}>
  
    <div className="col text-start mt-4">
    <h2 className={`${theme === 'light' ? 'text-dark' : 'text-light'} my-2 headerLabels`}>Team</h2>
    <div className='row mt-4'>
        <div className='col-lg-6'>
        <div className={`${theme === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} card mb-3 cardRadius `}>
  <div className="row g-0">
    <div className="col-8">
      <div className="card-body teamcard">
        <h5 className="card-title fw-bold  cardLabels">Alekhya Nagulapati</h5>
        <p className='paragraph mt-0 cardLabels'>Managing Director</p>
        <p className='paragraph cardpara cardLabels my-2'>A sjsolutions executive, Alekhya ensured successful outcome in all product development and customer experience, overall 10+ years of experience in the it industry.</p>
      <a href="https://in.linkedin.com/in/alekhya-pemmaraju-013578267" target="_blank"><button className='linkedinbutton mt-3 cardLabels'>LinkedIn <span><img src={arrowright} alt="icon" width={18}/></span></button></a>
      </div>
    </div>
    <div className="col-4">
      <img src={team1} className="img-fluid rounded-start" alt="icon" style={{margin: '13px 1px',width: '85%'}}/>
    </div>
  </div>
</div>
        </div>
        <div className='col-lg-6'>
        <div className={`${theme === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} card mb-3 cardRadius `}>
  <div className="row g-0">
    <div className="col-8">
      <div className="card-body teamcard">
      <h5 className="card-title fw-bold cardLabels">Laxmiprasad K</h5>
        <p className='paragraph mt-0  cardLabels'>Director, Head of UI/UX Designer</p>
        <p className='paragraph cardpara cardLabels my-2'>12+ Years of experience in UI/UX design. Skilled in various aspects of user interface and user experience design, from creating visually appealing interfaces to crafting user journey.</p>
      <a href="https://www.linkedin.com/in/laxmiprasad-k-491549244" target="_blank"><button className='linkedinbutton mt-3 cardLabels'>LinkedIn <span><img src={arrowright} alt="icon" width={18}/></span></button></a>
      </div>
    </div>
    <div className="col-4">
      <img src={team2} className="img-fluid rounded-start" alt="icon" style={{margin: '13px 1px',width: '85%'}}/>
    </div>
  </div>
</div>
        </div>
    </div>
    </div>
  </div>
  );
}

export default Team;
